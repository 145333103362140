import React from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image";
import { WideContainer, SlimSection } from "../Section";
import { colors as c } from "../../style";

const Companies = ({ items }) => {
  const rowStyle = `
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    
    @media (min-width: 700px) {
      flex-flow: row-reverse nowrap;
    }    
  `;

  const boxStyle = `
    margin: 2rem 4rem;
    max-width: 350px;
  `;

  return (
    <WideContainer bgColor={`${c.bg_section_alt}`}>
      <SlimSection>
        <div css={rowStyle}>
          {items.map(x => (
            <div key={x.companyname} css={boxStyle}>
              <Image fixed={x.img.childImageSharp.fixed} alt={x.sponsorname} />
            </div>
          ))}
        </div>
      </SlimSection>
    </WideContainer>
  );
};
Companies.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object)
};

export default Companies;
