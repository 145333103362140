import React from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image";
import { WideContainer, SlimSection } from "../Section";
import { Button } from "../Buttons";
import { colors as c } from "../../style";

const heroSectionStyle = `
    color: ${c.heading_main};
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: space-around;

    @media (min-width: 700px) {
      flex-flow: row wrap;
    }    
  `;

const heroImageStyle = `
    order: -1;
    margin: 1rem 0rem;
    padding: 0;
    flex-basis: 45%;
    width: 300px;

    @media (min-width: 700px) {
      width: 550px;
      text-align: center;
    }
  `;

const heroTextContainerStyle = `
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    flex-basis: 40%;
    text-align: right;

    @media all and (min-width: 700px) and (max-width: 960px) {
      background-color: inherit;
      align-items: center;
      flex-basis: 70%
      text-align: center;
    }
    
    @media all and (min-width: 961px) {
        align-items: center;
    }
  `;

const heroHeadingStyle = `
    font-size: 2.2rem;
    line-height: 3.5rem;
    font-weight: 600;
    text-align: center;
    margin: 1rem;
    text-align: right;

    @media (min-width: 700px) {
      font-weight: 400;
      font-size: 3rem;
      text-align: center;
    }
  `;

const heroSubHeadingStyle = `
    font-size: 1.2rem;
    margin: 1rem;
    text-align: center;

    @media (min-width: 700px) {
      font-size: 1.5rem;
    }
  `;

const Hero = ({
  heading,
  subheading,
  image,
  buttontext,
  bgColor,
  onButtonClick
}) => {
  const backgroundColor = bgColor || c.bg_hero;

  return (
    <WideContainer bgColor={backgroundColor}>
      <SlimSection css={heroSectionStyle}>
        <div css="position: relative">
          <Image
            css={heroImageStyle}
            fluid={image.img.childImageSharp.fluid}
            alt={image.text}
          />
        </div>
        <div css={heroTextContainerStyle}>
          <h2 css={heroHeadingStyle}>{heading}</h2>
          <div css={heroSubHeadingStyle}>{subheading}</div>
          <Button large id="cta-get-three-quotes" onClick={onButtonClick}>
            {buttontext}
          </Button>
        </div>
      </SlimSection>
    </WideContainer>
  );
};

Hero.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  image: PropTypes.object,
  buttontext: PropTypes.string,
  bgColor: PropTypes.string,
  onButtonClick: PropTypes.func
};

export default Hero;
