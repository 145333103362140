import React from "react";
import PropTypes from "prop-types";
import { FaCheck, FaTimes } from "react-icons/fa";

const checkListStyle = `
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    text-align: left;
    margin: 5px auto;
    
    > span:first-child {
      margin-right: 0.5rem;
      @media all and (min-width: 700px) {
        margin-right: 1rem;
        }    
    }
  }
`;

export const CheckList = ({ cross, tick }) => {
  const crossElt = cross.map(text => (
    <li key={text}>
      <span>
        <FaTimes
          css={`
            color: #fc3d45;
          `}
        />
      </span>
      <span>{text}</span>
    </li>
  ));
  const tickElt = tick.map(text => (
    <li key={text}>
      <span>
        <FaCheck
          css={`
            color: #6dd644;
          `}
        />
      </span>
      <span>{text}</span>
    </li>
  ));
  return <ul css={checkListStyle}>{[...crossElt, ...tickElt]}</ul>;
};

CheckList.propTypes = {
  cross: PropTypes.arrayOf(PropTypes.string),
  tick: PropTypes.arrayOf(PropTypes.string)
};
