import React from "react";
import PropTypes from "prop-types";
import { SectionTitle } from "../Section";

const sectionStyle = `
  padding-top: 20px;
  padding-bottom: 20px;
`;

const rowStyle = `
  min-height: 100px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
`;
/* eslint-disable react/jsx-no-target-blank */
class TrustBoxWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = { scriptLoaded: false };
  }
  componentDidMount() {
    this.loadScript();
  }

  loadScript() {
    const existingScript = document.getElementById("trustPilotScript");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src =
        "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
      script.async = true;
      script.id = "trustPilotScript";
      document.body.appendChild(script);
    }
    this.setState({ scriptLoaded: true });
  }

  renderCarousel() {
    return (
      <div
        className="trustpilot-widget"
        data-locale="en-AU"
        data-template-id="53aa8912dec7e10d38f59f36"
        data-businessunit-id="547586b500006400057bc4b1"
        data-style-height="130px"
        data-style-width="100%"
        data-theme="light"
        data-stars="4,5"
      >
        <a
          href="https://au.trustpilot.com/review/yoursolarquotes.com.au"
          target="_blank"
        >
          Trustpilot
        </a>
      </div>
    );
  }

  renderHorizontal() {
    return (
      <div
        className="trustpilot-widget"
        data-locale="en-AU"
        data-template-id="5406e65db0d04a09e042d5fc"
        data-businessunit-id="547586b500006400057bc4b1"
        data-style-height="28px"
        data-style-width="100%"
        data-theme="light"
        data-stars="1,2,3,4,5"
      >
        <a
          href="https://au.trustpilot.com/review/yoursolarquotes.com.au"
          target="_blank"
        >
          Trustpilot
        </a>
      </div>
    );
  }

  renderQuote() {
    return (
      <div
        className="trustpilot-widget"
        data-locale="en-AU"
        data-template-id="54d0e1d8764ea9078c79e6ee"
        data-businessunit-id="547586b500006400057bc4b1"
        data-style-height="100px"
        data-style-width="100%"
        data-theme="light"
        data-tags="SelectedReview"
      >
        <a
          href="https://au.trustpilot.com/review/yoursolarquotes.com.au"
          target="_blank"
        >
          Trustpilot
        </a>
      </div>
    );
  }

  renderStarter() {
    return (
      <div
        className="trustpilot-widget"
        data-locale="en-AU"
        data-template-id="539ad0ffdec7e10e686debd7"
        data-businessunit-id="547586b500006400057bc4b1"
        data-style-height="100px"
        data-style-width="100%"
        data-theme="light"
        data-tags="SelectedReview"
      >
        <a
          href="https://au.trustpilot.com/review/yoursolarquotes.com.au"
          target="_blank"
        >
          Trustpilot
        </a>
      </div>
    );
  }

  render() {
    if (!this.state.scriptLoaded) return null;

    let WidgetComponent;
    switch (this.props.widget) {
      case "carousel":
        WidgetComponent = () => this.renderCarousel();
        break;
      case "horizontal":
        WidgetComponent = () => this.renderHorizontal();
        break;
      case "quote":
        WidgetComponent = () => this.renderQuote();
        break;
      case "starter":
        WidgetComponent = () => this.renderStarter();
        break;
      default:
        return;
    }

    return (
      <div css={sectionStyle}>
        {this.props.title && <SectionTitle>{this.props.title}</SectionTitle>}
        <div css={rowStyle}>
          <WidgetComponent />
        </div>
      </div>
    );
  }
}

TrustBoxWidget.propTypes = {
  title: PropTypes.string,
  widget: PropTypes.string
};

export default TrustBoxWidget;
