import React from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image";
import { SectionTitle, WideContainer, SlimSection } from "../Section";
import { colors as c } from "../../style";

const Recommendations = ({ title, items }) => {
  const RecommendationsContainerstyle = `
    margin: 0 2rem 0 2rem;
    align-items: center;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    
    @media all and (min-width: 700px) {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-around;
    }
  `;

  const rowStyle = `
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    
    @media all and (min-width: 700px) {
      flex-flow: row wrap;
    }
  `;

  const boxStyle = `
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;

    @media all and (min-width: 700px) and (max-width: 960px) {
      flex-flow: column nowrap;
      flex-basis: 50%
      align-items: center;
    }

    @media all and (min-width: 961px) {
      flex-flow: row nowrap;
      flex-basis: 50%;
    }    
  `;

  const textContainerStyle = `
    height: 50%;
    margin: 0rem 2rem;
    max-width: 260px;
  `;

  const titleStyle = `
    text-align: center;
    font-size: 1.3rem;
    color: ${c.section_sub_heading};
    margin: 0rem auto;
    font-weight: 500;
  `;

  const imageStyle = `
    align-self: center;
  `;

  const textStyle = `
    text-align: center;
    font-size: 1.3rem;
    color: ${c.text_main_sub};
    margin: 2rem auto;
    font-weight: 300;
  `;

  return (
    <WideContainer>
      <SlimSection css={RecommendationsContainerstyle}>
        <SectionTitle>{title}</SectionTitle>
        <div css={rowStyle}>
          {items.map(x => (
            <div key={x.title} css={boxStyle}>
              <Image
                fixed={x.img.childImageSharp.fixed}
                alt={x.title}
                css={imageStyle}
              />
              <div css={textContainerStyle}>
                <h3 css={titleStyle}>{x.title}</h3>
                <p css={textStyle}>{x.text}</p>
              </div>
            </div>
          ))}
        </div>
      </SlimSection>
    </WideContainer>
  );
};

Recommendations.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object)
};

export default Recommendations;
