import React from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image";
import { SectionTitle, WideContainer, SlimSection } from "../Section";
import { CheckList } from "../CheckList";

const GuaranteesContainerstyle = `
  margin: 0 2rem 0 2rem;
  align-items: center;
  font-size: 1.2rem;
  color: #4B4B4B;

  @media all and (min-width: 700px) {
    font-size: 1.6rem;
  }
`;

const rowStyle = `
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
    
  > div, > ul {
    margin-bottom: 4rem;
    flex-grow: 1;
    flex-basis: 50%;
  }

  ul {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    li {
      margin-right: auto;
      margin-left: 0;
    }
  }
  
  @media all and (min-width: 960px) {
    flex-flow: row wrap;
    > div, > ul {
      flex-grow: 1;
      flex-basis: 50%;
      margin-bottom: 2rem;
    }
  }
`;

const cw = `
  display: flex;
  aign-items: center;
  justify-content: center;
`;

const Guarantees = ({ title, reviewsimage, cross, tick }) => {
  return (
    <WideContainer>
      <SlimSection css={GuaranteesContainerstyle}>
        <SectionTitle>{title}</SectionTitle>
        <div css={rowStyle}>
          <Image fluid={reviewsimage.img.childImageSharp.fluid} />
          <div css={cw}>
            <CheckList cross={cross} tick={tick} />
          </div>
        </div>
      </SlimSection>
    </WideContainer>
  );
};

Guarantees.propTypes = {
  title: PropTypes.string,
  reviewsimage: PropTypes.object,
  cross: PropTypes.arrayOf(PropTypes.string),
  tick: PropTypes.arrayOf(PropTypes.string)
};

export default Guarantees;
