import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { Hero, WhatWeDo, Recommendations, Companies } from "../components/main";
import TrustBoxWidget from "../components/trustbox";
import { ResponsiveWidthContainer } from "../components/Section";

export const MainPageTemplate = ({
  hero,
  whatwedo,
  companies,
  recommendations,
  trustbox
}) => (
  <>
    <Hero {...hero} />
    <WhatWeDo {...whatwedo} />
    <ResponsiveWidthContainer bgColor="#edeff1">
      <TrustBoxWidget widget="horizontal" />
    </ResponsiveWidthContainer>
    <Recommendations {...recommendations} />
    <Companies items={companies} />
    <ResponsiveWidthContainer>
      <TrustBoxWidget title={trustbox.carouseltitle} widget="carousel" />
    </ResponsiveWidthContainer>
  </>
);

MainPageTemplate.propTypes = {
  hero: PropTypes.object,
  whatwedo: PropTypes.object,
  companies: PropTypes.array,
  recommendations: PropTypes.object,
  trustbox: PropTypes.object
};

const MainPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout location={location}>
      <MainPageTemplate {...frontmatter} />
    </Layout>
  );
};

MainPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  }),
  location: PropTypes.object
};

export default MainPage;

export const mainPageQuery = graphql`
  query MainPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        templateKey
        meta {
          name
          content
        }
        headerbuttontext
        hero {
          heading
          subheading
          buttontext
          image {
            text
            img {
              childImageSharp {
                fluid(maxWidth: 550) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        whatwedo {
          title
          items {
            title
            text
            img {
              childImageSharp {
                fixed(width: 250) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        }
        recommendations {
          title
          items {
            title
            text
            img {
              childImageSharp {
                fixed(width: 250, height: 250) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        }
        companies {
          companyname
          img {
            childImageSharp {
              fixed(width: 350) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
        trustbox {
          carouseltitle
        }
      }
    }
  }
`;
